import React from "react";

export const LivePill = () => {
  return (
    <div
      className='flex items-center gap-x-1.5 
      rounded-full bg-green-50 px-2 
      py-1 text-green-900 dark:bg-green-600/30 dark:text-green-100'
    >
      <div className='h-2 w-2 animate-pulse rounded-full bg-green-500 dark:bg-green-300'></div>
      Live
    </div>
  );
};
