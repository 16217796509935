import { useMemo } from 'react';
import { usePaginatedQuery } from './usePaginatedQuery';
import { getPrompts } from '/services/joynApi/users/prompt';
import { QUERY_KEYS } from '/utils/queries';

const campaignsFormatter = (res) => ({
  data: res.data.data.rows,
  count: res.data.data.count,
});

export const useCampaigns = ({ size = 12, projectId, enabled = false, explore = false, filters = {} }) => {
  const {
    isLoading: isLoadingCampaigns,
    isFetchingNextPage: isLoadingNextCampaigns,
    data: campaignsData,
    hasNextPage: hasMoreCampaigns,
    fetchNextPage: fetchMoreCampaigns,
    refetch: refetchCampaigns,
  } = usePaginatedQuery(
    [QUERY_KEYS.CAMPAIGN.GET_ALL, projectId, filters?.search, explore],
    getPrompts,
    campaignsFormatter,
    {
      enabled: !!projectId || enabled,
    },
    { projectId, filters, size }
  );

  const { campaigns } = useMemo(
    () => ({
      campaigns: campaignsData?.map((item) => item.data).flat() ?? [],
    }),
    [campaignsData]
  );

  return useMemo(
    () => ({
      campaigns,
      isLoadingCampaigns,
      hasMoreCampaigns,
      isLoadingNextCampaigns,
      fetchMoreCampaigns,
      refetchCampaigns,
    }),
    [campaigns, isLoadingCampaigns, hasMoreCampaigns, isLoadingNextCampaigns, fetchMoreCampaigns, refetchCampaigns]
  );
};
