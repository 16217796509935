import React, { useMemo } from 'react';
import Countdown from 'react-countdown';
import pluralize from 'pluralize';
import { ClockIcon, EyeOffIcon } from '@heroicons/react/outline';
import diamondIcon from '/images/diamondIconWhite.svg';
import LockClosedOutlineIcon from '@heroicons/react/outline/LockClosedIcon';

import { formatDate } from '/utils/date';
import { staticImgSrc } from '/utils/image';
import { PROMPT_STATUSES, ROLE_NAMES } from '/utils/constants';
import { getRolesWithoutEveryoneRole } from '/utils/role';
import { promptCardImg, hideSubmissions } from '/utils/prompt';
import { filterPromptRewards, promptRewardTooltip } from '/utils/prompt-reward';
import { promptRewardTitle } from '/utils/prompt-reward';
import { sortByAsc } from '/utils/array';
import { ProjectMetaCard } from '/design-systems/Molecules/Cards/ProjectMetaCard';
import { Team } from '/design-systems/Molecules/Team';
import { getEditor } from '/design-systems/Molecules/Editor';
import { Card } from '/design-systems/Atoms/Card';
import { LivePill } from '/design-systems/Atoms/LivePill';
import { ScheduledPill } from '/design-systems/Atoms/ScheduledPill';
import { Typography } from '/design-systems/Atoms/Typography';
import { Alert } from '/design-systems/Atoms/Alert';
import { Image } from '/design-systems/Atoms/Image';
import { HyperlinkWrapper } from '/design-systems/Organisms/HyperlinkWrapper';
import { FloatingTooltip } from '/design-systems/Atoms/FloatingTooltip';
import { emptyArray } from '/utils/object';
import { mergeClassNames } from '/utils/string';
import { getBackgroundUrl } from '/utils/style';

const PromptRewardRow = ({ reward, disableMini }) => {
  const title = promptRewardTitle(reward) || '';
  const description = promptRewardTooltip(reward) || '';
  const rewardTitle = disableMini
    ? title
    : title.length > 12
    ? `${title.substring(0, 12)}...`
    : title || '';

  return (
    <FloatingTooltip label={`${rewardTitle}: ${description}`} placement="top">
      <div className="block flex w-fit items-center rounded-full bg-neutral-50 px-3 py-1 text-neutral-500 dark:bg-black/30 dark:text-neutral-100">
        <div className="mr-1.5 min-h-[16px] min-w-[16px]">
          <Image
            src={diamondIcon.src}
            staticFile={true}
            className="h-full w-full"
          />
        </div>
        <Typography
          variant="medium"
          weight="medium"
          className="flex flex-nowrap whitespace-nowrap"
        >
          {rewardTitle}
        </Typography>
      </div>
    </FloatingTooltip>
  );
};

export const PromptCard = ({
  projectsMeta,
  editable,
  title,
  description,
  rewards,
  endsIn,
  roles,
  onProjectClick,
  prompt,
  link,
  contributors,
  contributorsCount,
  className,
  id
}) => {
  const editor = getEditor(description);

  const submittable = useMemo(
    () =>
      editable ||
      (roles?.length === 1 && roles[0].name === ROLE_NAMES.everyone),
    [roles, editable]
  );

  const hasOnlyOneProject = projectsMeta ? projectsMeta.length === 1 : false;

  const cardImg = useMemo(() => promptCardImg(prompt), [prompt]);

  const isPublished = useMemo(
    () => prompt?.status === PROMPT_STATUSES.PUBLISHED,
    [prompt]
  );

  const ended = () => {
    return new Date(endsIn) < new Date();
  };

  rewards = sortByAsc({
    array: rewards,
    attribute: 'id'
  });

  const scheduled = () => {
    if (!prompt?.startDate) {
      return false;
    }

    return new Date(prompt?.startDate) > new Date();
  };

  const handleViewProject = (projectMeta) => {
    return (e) => {
      if (!submittable) return;
      e.preventDefault();
      e.stopPropagation();
      onProjectClick?.(projectMeta);
    };
  };

  const renderRewards = () => {
    const promptRewards = filterPromptRewards(rewards ?? []);
    const hasOver3PromptRewards = promptRewards.length > 3;
    const concatPromptRewardTitles = promptRewards.reduce(
      (val, reward) => `${val} ${promptRewardTitle(reward)}`,
      ''
    );
    const isLongPromptRewardTitle = concatPromptRewardTitles.length > 60;

    return (
      <div className="item-center flex max-w-full flex-wrap gap-2">
        {promptRewards.slice(0, 3).map((reward) => (
          <PromptRewardRow
            key={reward.id}
            reward={reward}
            disableMini={!hasOver3PromptRewards && !isLongPromptRewardTitle}
          />
        ))}
        {hasOver3PromptRewards && (
          <Typography
            variant="small"
            colorVariant="secondary"
            className="flex items-end"
          >
            {' '}
            ...
          </Typography>
        )}
      </div>
    );
  };

  return (
    <HyperlinkWrapper url={link} onClick={false}>
      <Card
        id={`prompt-card-${id}`}
        key={`prompt-card-${prompt.id}`}
        clickable={submittable}
        className={mergeClassNames(
          'campaign-card dark relative z-0 mb-0 flex h-full flex-col gap-y-6 overflow-hidden bg-cover bg-no-repeat p-6',
          'transition-shadow duration-200 hover:shadow-lg',
          className,
          `bg-[url('${getBackgroundUrl({
            background: cardImg?.src,
            isStaticFile: cardImg?.staticFile,
            dimension: 'xs'
          })}')]`
        )}
      >
        {/* background blur */}
        <div className="absolute inset-0 z-[-1] h-full rounded-xl bg-black/30 backdrop-blur-xl"></div>

        {/* meta */}
        {(!emptyArray(projectsMeta) || scheduled() || !emptyArray(roles)) && (
          <div className="flex flex-row gap-2">
            {projectsMeta.map((projectMeta, index) => (
              <div
                key={index}
                className="flex items-center justify-between gap-4"
              >
                {projectMeta && !!Object.keys(projectMeta).length && (
                  <ProjectMetaCard
                    {...projectMeta}
                    rounded
                    size={36}
                    color="white"
                    onClick={handleViewProject(projectMeta)}
                    showTitle={hasOnlyOneProject}
                  />
                )}

                {scheduled() && (
                  <div className="ml-4 flex items-center text-gray-600">
                    <EyeOffIcon className="h-4 w-4" />
                    <Typography
                      transform="capitalize"
                      variant="xsmall"
                      className="ml-1 font-bold"
                    >
                      Unpublished
                    </Typography>
                  </div>
                )}

                {!emptyArray(roles) &&
                  getRolesWithoutEveryoneRole(roles).map((role) => (
                    <Alert
                      key={role.id}
                      variant="primary"
                      color="neutral"
                      icon={
                        <LockClosedOutlineIcon className="h-[20px] w-[20px] stroke-[#111827]" />
                      }
                    >
                      {role.name}
                    </Alert>
                  ))}
              </div>
            ))}
          </div>
        )}

        <div className="contest-card-details flex flex-col gap-2">
          <Typography
            heading
            variant="h5"
            weight="semibold"
            color="white"
            className="line-clamp-1"
          >
            {title}
          </Typography>
          <Typography variant="small" className="line-clamp-2 text-neutral-200">
            {editor &&
              (description && description.length > 150
                ? `${editor.getText().substring(0, 150)}...`
                : editor.getText() || '')}
          </Typography>
        </div>

        {!!cardImg && (
          <Image
            src={cardImg.src}
            staticFile={cardImg ? cardImg.staticFile : true}
            className="h-[200px] w-full rounded-lg"
            style={{ objectFit: 'cover' }}
            resolution="m"
          />
        )}

        <div className={`item-center flex gap-2`}>{renderRewards()}</div>

        <div className="item-center flex flex-row flex-wrap gap-6">
          {ended() ? (
            <div className="block flex items-center">
              <div className="mr-1.5">
                <ClockIcon className="h-4 w-4 text-neutral-200" />
              </div>
              <div>
                <Typography variant="medium" className="text-neutral-200">
                  Ended {formatDate(new Date(endsIn))}
                </Typography>
              </div>
            </div>
          ) : (
            <div className="block flex items-center gap-x-2 text-neutral-200">
              {isPublished ? (
                scheduled() ? (
                  <ScheduledPill />
                ) : (
                  <LivePill />
                )
              ) : (
                <ScheduledPill label={prompt?.status} />
              )}
              <div>
                <Countdown
                  date={
                    scheduled() ? new Date(prompt?.startDate) : new Date(endsIn)
                  }
                  intervalDelay={0}
                  precision={3}
                  renderer={({ days, hours, minutes }) => {
                    if (days > 0) {
                      return (
                        <span>
                          {scheduled() && 'Start in'} {days}{' '}
                          {pluralize('days', parseInt(days))}{' '}
                          {!scheduled() && 'left'}
                        </span>
                      );
                    }
                    if (hours > 0) {
                      return (
                        <span>
                          {scheduled() && 'Start in'} {hours}{' '}
                          {pluralize('hours', parseInt(hours))}{' '}
                          {!scheduled() && 'left'}
                        </span>
                      );
                    }

                    return (
                      <span>
                        {scheduled() && 'Start in'} {minutes}{' '}
                        {pluralize('minutes', parseInt(minutes))}{' '}
                        {!scheduled() && 'left'}
                      </span>
                    );
                  }}
                />
              </div>
            </div>
          )}

          {contributorsCount > 0 && !hideSubmissions(prompt) && (
            <div className="mr-6 block flex items-center">
              <Team
                noHover={true}
                users={contributors}
                usersCount={contributorsCount}
                required={2}
                rounded
                size={32}
              />
            </div>
          )}
        </div>
      </Card>
    </HyperlinkWrapper>
  );
};
