import React from 'react';

import { mergeClassNames } from '/utils/string';

export const ScheduledPill = ({ label = 'Scheduled' }) => {
  return (
    <div
      className={mergeClassNames(
        'text-white-900 dark:text-white-100 flex',
        'items-center gap-x-1.5 rounded-full',
        'bg-gray-50 px-2 py-1 dark:bg-gray-600/30',
        'capitalize'
      )}
    >
      <div className="h-2 w-2 animate-pulse rounded-full bg-gray-500 dark:bg-gray-300"></div>
      {label}
    </div>
  );
};
