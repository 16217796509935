import axios from '/services/axios';
import { generateQueryParams } from '/utils/url';

const getPrompts = ({ projectId, sortBy = 'desc', page = 1, size = 12, filters }) => {
  const query = generateQueryParams({ page, size, sortBy, ...filters });
  if (projectId) {
    return axios.get(`/prompts/${projectId}?${query}`);
  } else {
    return axios.get(`/prompts?${query}`);
  }
};

const getPrompt = (id) => {
  return axios.get(`/prompt/${id}`);
};

export { getPrompts, getPrompt };
