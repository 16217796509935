import axios from '/services/axios';

const getRoles = (projectId) => {
  return axios.get(`/roles/${projectId}`);
};

const createRole = (payload) => {
  return axios.post('/role', payload);
};

const updateRole = ({ payload, roleId }) => {
  return axios.put(`/role/${roleId}`, payload);
};

const verifyToken = ({ chain, type, tokenAddress }) => {
  return axios.get(`/verify/${chain}/${type}/${tokenAddress}`);
};

export { getRoles, createRole, verifyToken, updateRole };
