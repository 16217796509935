import React from 'react';
import { Avatar } from '/design-systems/Atoms/Avatar';
import { Typography } from '/design-systems/Atoms/Typography';

export const ProjectMetaCard = ({
  title,
  logo,
  size = 48,
  onClick,
  weight = 'semibold',
  colorVariant = 'primary',
  color,
  showTitle = true
}) => {
  return (
    <div className="team flex max-w-[250px] items-center overflow-hidden">
      <div
        className={`flex items-center gap-x-2 overflow-hidden ${
          onClick && 'cursor-pointer'
        }`}
        onClick={(e) => onClick?.(e)}
      >
        <Avatar
          resolution="xl"
          src={logo}
          size={size}
          roundedSize="lg"
          bordered
        />
        {showTitle && (
          <Typography
            variant="medium"
            weight={weight}
            colorVariant={colorVariant}
            color={color}
            overflow="truncate"
          >
            {title}
          </Typography>
        )}
      </div>
    </div>
  );
};
