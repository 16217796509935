import { getAvatar, getLogo } from '/utils/image';

export const mapUser = ({ id, name, username, ...data }) => {
  return {
    id,
    name,
    username,
    avatar: getAvatar(data),
  };
};

export const mapProjectToStore = (project) => {
  return {
    project,
    coverImage: project.Files.find(({ type }) => type == 'cover'),
    logoImage: project.Files.find(({ type }) => type == 'logo'),
    users: project.ProjectCollaborators?.map(({ User }) => mapUser(User)) || [],
    roles: project.roles,
  };
};

export const isProducer = (users, { id }) => {
  const user = users?.find((u) => u.id === id);
  return !!user;
};

export const mapProjectMeta = (project) => ({
  title: project?.title,
  slug: project.slug,
  logo: getLogo(project),
});
