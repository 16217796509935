import { ROLE_NAMES, FEATURES } from '/utils/constants';
import { verifyToken } from '/services/joynApi/users/role';

const validateRoleForm = async (payload) => {
  const validation = {};

  if (!payload.chain) {
    validation.chain = 'This is a required field';
  }

  if (!payload.tokenAddress) {
    validation.tokenAddress = 'This is a required field';
  }

  if (!payload.type) {
    validation.type = 'This is a required field';
  }

  if (payload.chain && payload.tokenAddress && payload.type) {
    const {
      data: { data: res },
    } = await verifyToken(payload);
    if (!res.isValid) {
      validation.tokenAddress = 'This is an invalid token address. Try selecting another network or token type';
    }
  }

  return validation;
};

const getEveryoneRole = (roles) => {
  return roles.find((role) => role.name === ROLE_NAMES.everyone);
};

const getRolesWithoutEveryoneRole = (roles) => {
  if (!roles) return [];

  return roles.filter((role) => role.name !== ROLE_NAMES.everyone);
};

const getCollectionLinkFromRole = ({ requirements }) => {
  if (!requirements?.length) return '';
  return requirements[0].collectionUrl;
};

const filterRoles = (roles) => {
  return Object.keys(roles)
    .filter((roleId) => roles[roleId])
    .map((roleId) => ({
      id: roleId,
      feature: FEATURES.promptSubmission,
    }));
};

const getRoleName = (role) => {
  return role?.Requirements?.[0]?.collectionName || role?.requirements?.[0]?.collectionName || role?.name;
};

export {
  filterRoles,
  getEveryoneRole,
  validateRoleForm,
  getCollectionLinkFromRole,
  getRolesWithoutEveryoneRole,
  getRoleName,
};
